.App {
    text-align: center;
    background-color: black;
    color: white;
  }

.image{
    display: inline-block;  
    max-width: 80%;
    height: auto;
    }

.lightboxholdup{
  max-width: 820px;
  margin: 0 auto;
  display: inline-block;
  overflow: hidden;
}