  
  .ril__navButtons {
    background-color: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 42px;
    height: 42px;
    margin: auto;
    cursor: pointer;
    opacity: 0.42;
    color: white;
  }
  .ril__navButtons:hover {
    opacity: 1;
  }
  .ril__navButtons:active {
    opacity: 0.7;
  }
  
  .ril__navButtonPrev {
    left: 4.2%;
  }  
  .ril__navButtonNext {
    right: 4.2%;
    
    }
  


  .ril__closeButton {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 4.2%;
    right: 4.2%;
    opacity: 0.42;
    width: 42px;
    height: 42px;
    margin: auto;
    cursor: pointer;
    color: white;
    }
  
    .ril__closeButton:hover {
      opacity: 1;
    }
    .ril__closeButton:active {
      opacity: 0.7;
    }
    