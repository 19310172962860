*{
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  /* text-align: center; */
}




.App-logo {
  margin-top: 5%;
  display: inline-block;
  pointer-events: none;
  background-color: black;
  align-items: center;
  height: auto; 
  width: auto; 
  max-width: 42%; 
  object-fit: contain;
}
